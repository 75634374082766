ul.main-navigation {
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
}

ul.main-navigation li {
    display: block;
    position: relative;
    float: left;
    background: #fff;
}

ul.main-navigation li ul {
    display: none;
}

ul.main-navigation li a {
    display: block;
    padding: 1em;
    text-decoration: none;
    white-space: nowrap;
    color: #000;
}

ul.main-navigation li a:hover {
    background: #fff;
    color: #ba110b;
}

ul.main-navigation li:hover>ul {
    display: block;
    position: absolute;
}

ul.main-navigation li:hover li {
    float: none;
}

ul.main-navigation li:hover a {
    background: #fff;
}

ul.main-navigation li:hover li a:hover {
    background: #fff;
}

.main-navigation li ul li {
    border-top: 0;
}

ul.main-navigation ul ul {
    left: 100%;
    top: 0;
}

ul.main-navigation ul:before,
ul.main-navigation ul:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

ul.main-navigation ul:after {
    clear: both;
}

ul.main-navigation li.submenu-has-child,
ul.main-navigation li.menu-has-child {
    padding-right: 1.1em;
}

ul.main-navigation li.submenu-has-child::before {
    content: "▶";
    font-size: 12px;
    float: right;
    color: #000;
    margin-top: 18px;
}

ul.main-navigation li.menu-has-child::before {
    content: "▼";
    font-size: 12px;
    float: right;
    color: #000;
    margin-top: 18px;
}

ul.main-navigation li.submenu-has-child:hover::before {
    color: #ba110b;
}

ul.main-navigation li.menu-has-child:hover::before {
    color: #ba110b;
}

/********************************/

ul.main-navigation-burger-mode {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: calc(100vh - 350px);
    overflow-y: auto;
}

ul.main-navigation-burger-mode>li {
    /*border-bottom: 1px solid #ba110b;*/
}

ul.main-navigation-burger-mode li {
    float: none;
    text-align: center;
    display: block;
    position: relative;
}

ul.main-navigation-burger-mode li a {
    display: block;
    padding: 1em;
    text-decoration: none;
    white-space: nowrap;
    color: #000;
}

ul.main-navigation-burger-mode li a:hover {
    background: #fff;
    color: #ba110b;
}

.main-navigation-burger-mode li ul li {
    border-top: 0;
}

ul.main-navigation-burger-mode ul ul {
    left: 100%;
    top: 0;
}

.burger-submenu {
    margin-top: 15px;
}


.burger-submenu-button-open::before {
    content: "+";
    font-weight: 900;
    /* Plus sign for open */
    color: #ba110b;
}

.burger-submenu-button-close::before {
    content: "–";
    font-weight: 900;
    /* Minus sign for close */
    color: #ba110b;
}