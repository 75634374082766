/* TailwindCSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* General Reset and Styles */
* {
  box-sizing: border-box;
  line-height: normal;
}
body {
  font-family: "Proxima Nova" !important;
  margin: 0;
  padding: 0 !important;
}
.battersea-code,
.battersea-navigation {
  min-height: 100%;
}
.common-pointer {
  cursor: pointer;
}
table {
  border-spacing: 0;
  width: 100%;
}
.common-select {
  z-index: 10000;
}
.react-datepicker-popper {
  z-index: 100000 !important;
}
option {
  color: #000;
}
.table-wrap {
  overflow: auto;
}
input:focus {
  outline: none;
}
.ReactModal__Body--open {
  overflow: hidden;
}
.slider-indicator:first-child {
  margin-left: 0;
}
.slider-indicator:last-child {
  margin-right: 0;
}
.rc-drawer-content-wrapper {
  width: 100% !important;
}
.rc-drawer-right .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-end;
}
.rc-drawer-left .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-start;
}
.ReactModal__Overlay {
  z-index: 1051;
}
ul li a {
  display: block;
}
.otp-input {
  width: 100px !important;
  height: 56px !important;
  font-size: 14px !important;
}
@media (max-width: 575px) {
  .otp-input {
    width: 50px !important;
    height: 50px !important;
    font-size: 20px !important;
  }
}


/* Responsive Styles for HomeContent Components */

/* Shared Styling for Content Sections */
.content-center-section {
  text-align: center;
  margin: 2.5rem auto;
  padding: 1rem 1.5rem;
  max-width: 100%;
  box-sizing: border-box;
}

/* HomeContent1, HomeContent2, HomeContent3, and HomeContent4 Headings and Paragraphs */
.content-center-section h1,
.content-center-section h2,
.content-center-section h3,
.content-center-section h4,
.content-center-section h5 {
  font-weight: 600;
  margin-bottom: 1rem;
}

/* Base Font Sizes */
.content-center-section h1 {
  font-size: 2rem; /* For large displays */
}
.content-center-section h2 {
  font-size: 1.75rem;
}
.content-center-section h3 {
  font-size: 1.5rem;
}
.content-center-section h4 {
  font-size: 1.25rem;
}
.content-center-section h5 {
  font-size: 1.125rem;
}
.content-center-section p,
.content-center-section ul li {
  font-size: 1rem;
  line-height: 1.6;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

/* Responsive Adjustments */

/* Tablet Screens */
@media (max-width: 768px) {
  .content-center-section h1 {
    font-size: 1.75rem;
  }
  .content-center-section h2 {
    font-size: 1.5rem;
  }
  .content-center-section h3 {
    font-size: 1.25rem;
  }
  .content-center-section h4 {
    font-size: 1.125rem;
  }
  .content-center-section h5 {
    font-size: 1rem;
  }
  .content-center-section p,
  .content-center-section ul li {
    font-size: 0.95rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/* Mobile Screens */
@media (max-width: 480px) {
  .content-center-section h1 {
    font-size: 1.5rem;
  }
  .content-center-section h2 {
    font-size: 1.25rem;
  }
  .content-center-section h3 {
    font-size: 1.125rem;
  }
  .content-center-section h4 {
    font-size: 1rem;
  }
  .content-center-section h5 {
    font-size: 0.875rem;
  }
  .content-center-section p,
  .content-center-section ul li {
    font-size: 0.875rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

/* Bullet List Styles */
.content-center-section ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  text-align: left;
}
.content-center-section ul li {
  margin-bottom: 0.5rem;
}
