


@font-face {
    font-family: "Proxima Nova";
    src: url('../../public/assets/fonts/ProximaNova-Regular.otf') format('opentype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Proxima Nova Semibold";
    src: url('../../public/assets/fonts/Proxima Nova Semibold-webfont.ttf') format('truetype');
    font-style: bold;
    font-weight: 600;
}

@font-face {
    font-family: 'Proxima Nova Bold';
    src: url('../../public/assets/fonts/Proxima Nova Bold.otf') format('opentype');
    font-style: bold;
    font-weight: 700;
}

@font-face {
    font-family: 'Trajan Pro';
    src: url('../../public/assets/fonts/TrajanPro-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Trajan Pro';
    src: url('../../public/assets/fonts/TrajanPro-Bold.otf') format('opentype');
    font-style: bold;
    font-weight: 700;
}

.font-trajanpro {
    font-family: 'Trajan Pro';
}
.font-proximanova {
    font-family: 'Proxima Nova';
}
.font-semibold {
    font-weight: 600;
}