/* Container for the grid */
.tutor-grid-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
  }
  
  /* Title styling */
  .tutor-grid-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #ef4444; /* Red-500 */
    margin-bottom: 2rem;
    font-family: 'Trajan Pro', serif;
  }
  
  /* Grid layout */
  .tutor-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr); /* Default: 1 column for mobile */
  }
  
  /* Card styling */
  .tutor-card {
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    overflow: hidden;
  }
  
  /* Image styling */
  .tutor-image {
    width: 100%;
    height: 256px;
    object-fit: cover;
    cursor: pointer;
  }
  
  /* Card body styling */
  .tutor-card-body {
    padding: 1.5rem;
  }
  
  /* Tutor name styling */
  .tutor-name {
    font-size: 1.25rem;
    font-weight: bold;
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  /* Tutor badge styling */
  .tutor-badge {
    background: #6b7280; /* Gray-500 */
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }
  
  /* Tutor description styling */
  .tutor-description {
    color: #374151; /* Gray-700 */
    margin-top: 0.5rem;
  }
  
  /* Tutor subjects styling */
  .tutor-subjects {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  
  /* Individual subject styling */
  .tutor-subject {
    border: 1px solid #ef4444; /* Red-500 */
    color: #ef4444; /* Red-500 */
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }
  
  /* Responsive adjustments */
  @media (min-width: 640px) {
    /* Tablet: 2 columns */
    .tutor-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    /* Desktop: 4 columns */
    .tutor-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }