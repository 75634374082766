
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #ba110b;
}
/* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='search']:focus,
[type='checkbox']:focus,
[type='radio']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: var(--primary-color);
  border-color: var(--primary-color);
}
:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
.react-datepicker-wrapper .react-datepicker__input-container input{
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0px;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}
input:focus, textarea:focus{
  outline:none !important;
  outline-offset: 0 !important;
  --tw-ring-offset-width:none !important;
}

li:focus-visible{
  outline: none;
}

li:hover{
  cursor: pointer;
}

.tab-panel--selected { animation-name: tabPanel; animation-duration: 300ms; animation-timing-function: linear; }
@keyframes tabPanel { from { opacity: 0; } to { opacity: 1; } }
/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container label {
  position: absolute;
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
button[disabled]{
  background: #C4C4C4;
  border: 1px solid #C4C4C4;
}
.google-login-button{
  width: 100% !important;
  display: block !important;
}
.lesson-heading{
  white-space: nowrap;
  max-width: 800px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.overflow-200{
  white-space: nowrap;
  max-width: 200px;
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
.max-w-full_200{
  max-width: calc(100% - 200px);
}
@media (max-width: 1199px) {
  .lesson-heading,.max-w-full_200{
    max-width: fit-content;
  }
}
.bg-gray_600{
  background-color: #a9acaa;
}

@media (min-width: 1600px) {
  .max-w-\[1110px\]{
      max-width: 1410px;
  }
  .max-w-\[1110px\].only-1110{
    max-width: 1110px;
  }
  .max-w-\[1140px\]{
    max-width: 1440px;
  }
  .max-w-\[1140px\].only-1110{
    max-width: 1110px;
  }
  .max-w-\[730px\]{
    max-width: 920px;
  }
}
