:root { 
    --gray_50_01:#FFF8F6; 
    --gray_50_02:#fff8f3; 
    --blue_gray_50:#eff1f1; 
    --red_900:#ba110b; 
    --red_500:#ed4731; 
    --green_800:#2e7d32; 
    --orange_300_01:#fbbb4a; 
    --gray_50:#f9f9f9; 
    --green_A400:#3ae180; 
    --deep_purple_A200:#9747ff; 
    --red_900_5e:#ba110b5e; 
    --teal_500:#00a297; 
    --black_900:#000000; 
    --gray_200_01:#eeeeee; 
    --black_900_28:#00000028; 
    --green_A400_00:#3ae18000; 
    --purple_400:#b05a8c; 
    --gray_600:#808080; 
    --gray_50_99:#f9f9f999; 
    --gray_900_02:#252525; 
    --gray_900_03:#31111d; 
    --black_900_0a:#0000000a; 
    --gray_400:#c4c4c4; 
    --gray_900_04:#191c1d; 
    --blue_gray_100:#cac4d0; 
    --gray_500:#a9acaa; 
    --gray_900:#1c1b1f; 
    --gray_900_01:#1f1f1f; 
    --gray_5c:#5C5F5F;
    --light_blue_50:#edfcff; 
    --gray_200:#e8e8e8; 
    --orange_300:#feba4b; 
    --white_A700_87:#ffffff87; 
    --orange_50:#ffeeda; 
    --light_green_50:#edf7ed; 
    --green_600_00:#27ae6000; 
    --blue_700:#5394da; 
    --black_900_19:#00000019; 
    --black_900_87:#00000087; 
    --black_00: #000000;
    --white_A700:#ffffff; 
    --gray_FA:#fafafa;
    --gray_e8:#E8E8E8;
    --gray_300: #d0d5dd;
}