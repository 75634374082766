@charset "UTF-8";
/* body overflow css */
body.overflow-hidden,
body[style^="padding-right: 6px"]{
    padding-right: 6px !important;
    @media (max-width: 991px) {
        padding-right: 0px !important;
    }
}
body[style^="padding-right: 5px"]{
    padding-right: 5px !important;
    overflow: auto !important;
    @media (max-width: 991px) {
        padding-right: 0px !important;
    }
}
body.overflow-hidden {
    overflow: hidden !important;
}
.hm-sec2 .w-\[25\%\]{
    z-index: 1;
}

#profile-menu-student {
    .MuiMenuItem-root {
        border-bottom: 1px solid var(--gray_200) !important;
        display: flex;
        justify-content: flex-start;
        padding: 7px 12px;
        &:last-child {
            border-bottom: none !important;
        }
    }
}

/* loader */
.lds-ring {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    margin: auto;
    display: inline-block;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 99999;
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        transform: translate(-50%, -50%);
        width: 64px;
        height: 64px;
        margin: auto;
        border: 8px solid var(--primary-color);
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: var(--primary-color) transparent transparent transparent;
        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.min-height-100vh-90 {
    min-height: calc(100vh - 160px);
    @media (max-width: 550px) {
        min-height: auto;
        padding: 30px 16px;
        .w-600 {
            min-height: calc(100vh - 74px);
        }
    }
}

.tutor-min-height-100vh-74 {
    min-height: calc(100vh - 74px);    
    .w-600 {
        width: 100%;
        max-width: 600px;
        min-height: calc(100vh - 120px);
    }
    @media (max-width: 550px) {
        min-height: calc(100vh - 70px);
        .w-600 {
            min-height: calc(100vh - 88px);
        }
    }
}

.select-option {
    min-height: 302px;

    @media (min-width: 1049px) and (max-width: 1199px) {
        min-height: 330px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 16px;
        right: 16px;
        width: 20px;
        height: 20px;
        border: 1px solid #808080;
        border-radius: 50%;
        background-color: #fff;
        opacity: 2;
        transition: ease-in-out 0.2s;
    }

    input[type="radio"] {
        &~img {
            opacity: 0;
            transition: ease-in-out 0.2s;
        }

        &:checked~img {
            opacity: 1;
        }
    }
}

/* custom input */
.MuiFormControl-root {
    .MuiFormLabel-root {
        font-size: 14px ;
        line-height: 24px ;
        font-family: Proxima Nova ;
        transform: translate(12px, 15px) scale(1) ;
        &.Mui-focused {
            color: var(--gray_600);
            font-size: 12px ;
            line-height: 16px ;
            transform: translate(12px, 8px) scale(1) ;
        }

        &.MuiInputLabel-shrink {
            color: var(--gray_600);
            font-size: 12px ;
            line-height: 16px ;
            transform: translate(12px, 8px) scale(1) ;
            &.Mui-error{
                color: var(--primary-color) !important;
            }
        }
    }

    .MuiOutlinedInput-root {
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--gray_600);
            border-radius: 0px;

            legend {
                span {
                    display: none;
                }
            }

            &:hover,
            &:focus,
            &.Mui-focused,
            &:focus-visible,
            &:focus-within {
                border: 1px solid var(--black_900);
            }
        }

        &:hover,
        &:focus,
        &.Mui-focused,
        &:focus-visible,
        &:focus-within {
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid var(--black_900);
            }
        }
    }

    .MuiInputBase-root {
        background-color: transparent;
        padding: 25px 12px 8px !important;
        border-radius: 0px;

        &:focus,
        &:hover,
        &.Mui-focused,
        &:focus-visible,
        &:focus-within {
            background-color: transparent !important;
        }

        &::before {
            top: 0px;
            border: 1px solid var(--gray_600);
        }

        &::after {
            top: 0px;
            border: 1px solid var(--black_900);
        }

        &.Mui-error::after,
        &.Mui-error::before {
            border-bottom: none;
            border: 1px solid var(--primary-color);
        }

        .MuiInputBase-input {
            background-color: #fff;
            padding: 0px 0px !important;
            -webkit-overflow-scrolling: touch;
            scrollbar-color: transparent var(--blue_gray_50);
            scrollbar-width: thin;
            font-size: 14px;
            min-height: 23px;
            width: calc(100% - 40px);
            overflow: hidden;
            text-overflow: ellipsis;
            @media (max-width: 575px) {
                padding: 4px 20px;
            }

            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
                background-color: var(--blue_gray_50);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--gray_200);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-track {
                border: 0.0625rem solid var(--blue_gray_50);
                border-radius: 2px;
            }
            &.MuiSelect-select{
                padding: 25px 12px 8px !important;
                margin: -25px -12px -8px !important;
                background: transparent !important;
                text-transform: capitalize;
                width: 100%;
            }
        }
    }
    .MuiSvgIcon-root.MuiSelect-iconFilled,
    .MuiAutocomplete-endAdornment .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root{
        background: url('../../public/images/back-arrow-black.svg') no-repeat center center;
        background-size: contain;
        width: 24px;
        height: 24px;
        transform: rotate(-90deg) !important;
        &.MuiSelect-iconOpen{
            transform: rotate(90deg) !important;
        }
        path {
            display: none;
        }
    }
    .MuiAutocomplete-endAdornment .MuiButtonBase-root.MuiIconButton-root[aria-label="Clear"]{
        display: none !important;
    }
    .MuiAutocomplete-endAdornment .MuiButtonBase-root.MuiIconButton-root{
        &:hover{
            background: transparent !important;
        }
    }
    .MuiTouchRipple-root{
        display: none !important;
    }
    &.empty-display-label{
        .MuiFormControl-root .MuiInputBase-root{
            padding-top: 12px !important;
        }
    }
}
.without-label-textarea{
    .MuiFormControl-root .MuiInputBase-root{
        padding-top: 12px !important;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.overflow-max-60 {
    max-height: 60vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: var(--gray_200) var(--blue_gray_50);
    scrollbar-width: thin;

    @media (max-width: 575px) {
        padding: 4px 20px;
    }

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: var(--blue_gray_50);
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--gray_200);
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border: 0.0625rem solid var(--blue_gray_50);
        border-radius: 2px;
    }
}

.small-input {
    .MuiFormControl-root {
        width: 100%;

        .MuiFormLabel-root {
            font-size: 14px !important;
            transform: translate(14px, 13px) scale(1) !important;

            &[data-shrink="true"] {
                transform: translate(11px, 8px) scale(0.75) !important;
            }
        }

        .MuiInputBase-root {
            padding: 20px 12px 5px !important;

            .MuiInputBase-input {
                font-size: 14px;
                height: 23px;
                &.MuiSelect-select{
                    padding: 20px 12px 5px !important;
                    margin: -20px -12px -5px !important;
                }
            }

            .MuiInputAdornment-root,
            svg[data-testid="EventIcon"] {
                transform: translate(0px, -8px);
            }
            
        }
    }
}

.filter-drop {
    .MuiFormControl-root {
        width: 100%;

        .MuiFormLabel-root {
            font-size: 14px !important;
            transform: translate(14px, 13px) scale(1) !important;
        }

        .MuiInputBase-root {
            padding: 7px 12px 6px !important;

        }

        .MuiSelect-select,
        .MuiInputBase-input {
            font-size: 14px !important;
        }
    }
}

.w-600 {
    width: 100%;
    max-width: 600px;
    min-height: calc(100vh - 135px);
}

.max-w-400 {
    max-width: 400px;
}

.max-w-460 {
    max-width: 460px;
}

.max-w-480 {
    max-width: 480px;
}

.max-w-560 {
    max-width: 560px;
}

.max-w-800 {
    max-width: 800px;
}

/* mobile input  */
.mobile-input {
    position: relative;

    .mobile-input__country-code {
        width: 100%;
        max-width: fit-content;
        height: 30px;
        margin-right: 10px;  
        margin-top: 13px;    
        margin-left: 1px;  
        position: absolute;
        background-color: transparent !important;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;      
        font-size: 14px;  
        color: var(--gray_600);

        &:hover,
        &:focus,
        &.Mui-focused,
        &:focus-visible,
        &:focus-within {
            background-color: transparent !important;
        }

        .MuiInputBase-input {
            background-color: transparent !important;
            padding: 0px 0px 0px 0px !important;

            &::after {
                content: '';
                position: absolute;
                top: 0px;
                bottom: 0px;
                right: 3px;
                width: 1px;
                height: 36px;
                margin: auto;
                background-color: var(--gray_200);

            }
        }

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }

        &::before,
        &:after {
            border: none !important;
            display: none;
        }
        .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline{
            width: 100%;
            padding: 15px 30px 15px 15px !important;
            margin: 0 !important;
            &::before,
            &:after {
                border: none !important;
                display: none;
            }
        }
    }

    .MuiInputLabel-root {
        margin-left: 70px;
    }

    .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
        padding-left: 0px !important;
        max-width: calc(100% - 70px);
        margin-left: auto;
        position: relative;
    }
}

/* .ck editor*/
.ck {

    .ck-toolbar,
    .ck-editor__editable_inline {
        border-radius: 0px !important;
        border: 1px solid var(--gray_600) !important;
        border-radius: 0px;
    }

    .ck-editor__editable_inline {
        border-top: none !important;
        border-radius: 0px !important;
    }

    .ck-editor__editable {
        min-height: 100px;
        border-radius: 0px !important;
    }
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-radius: 0px !important;
}

.custom-styles {
    display: flex;
    gap: 10px;
    width: 100%;
    div{
        display: flex;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
        @media (max-width:575px) {
            justify-content: center;
        }
    }
    .ReactInputVerificationCode__container {
        width: 100%;

        .ReactInputVerificationCode__item {
            font-weight: 500;
            color: #262626;
            border: 1px solid var(--gray_600);
            border-radius: 0px;
            width: 100px;
            height: 56px;
            line-height: 56px;
            box-shadow: none;

            &.is-active {
                border: 1px solid var(--black_900);
            }
        }
    }
}

.MuiTypography-body1 {
    font-size: 14px !important;
    font-family: 'Proxima Nova', sans-serif !important;
}
.MuiRadio-root.MuiRadio-colorPrimary{
    color: #C4C4C4;
}
.Mui-checked {
    color: var(--red_900) !important;
}

/* sidebar*/
.ps-sidebar-root {
    min-height: calc(100vh - 70px);
    padding: 30px 16px;
    border: none !important;
    @media (max-width: 991px) {
        max-height: calc(100vh - 102px);
    }
    .ps-menuitem-root {
        .ps-menu-button {
            height: 48px;
            font-size: 14px;
            font-family: 'Proxima Nova', sans-serif;

            &.ps-active {
                background-color: var(--blue_gray_50);
            }

            .ps-menu-label {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }

    .MuiCollapse-root {
        background-color: var(--blue_gray_50);
        transition: all 0.2s ease-in-out;

        .ps-menuitem-root {
            .ps-menu-button {
                height: 30px;
                padding-left: 70px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: 52px;
                    width: 6px;
                    height: 6px;
                    margin: auto;
                    border-radius: 50%;
                    background-color: var(--red_900);
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                }

                &.ps-active {
                    background-color: var(--blue_gray_50);

                    &::before {
                        opacity: 1;
                    }
                }
            }

            &.ps-active {
                background-color: var(--blue_gray_50);

                .ps-menu-button::before {
                    opacity: 1;
                }
            }
        }

        &.MuiCollapse-entered {
            padding-bottom: 10px;

            &~.ps-menuitem-root {
                .ps-menu-button {
                    background-color: var(--blue_gray_50);
                }
            }
        }
    }
}

.dot-4 {
    position: relative;
    padding-left: 24px;

    &::before {
        content: '';
        position: absolute;
        top: 4px;
        left: 0px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--red_900);
    }    
}
.checked-li{
    position: relative;
    padding-left: 24px;

    &::before {
        content: '';
        position: absolute;
        top:-2px;
        left: 0px;
        width: 16px;
        height: 16px;
        background: url('../../public/images/check-li.svg') no-repeat center center;
    }    
}

.react-sweet-progress-circle-outer {
    min-width: 40px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: transparent;
        border-radius: 50%;
        border: 1.5px solid var(--gray_400);
        margin: auto;
        z-index: 0;
    }

    svg {
        position: relative;
        z-index: 1;
    }

    .react-sweet-progress-symbol-absolute {
        .react-sweet-progress-symbol {
            font-size: 10px;
            font-weight: 700;
            color: var(--black_00);
        }
    }
}

/* table content*/
.MuiTableContainer-root {
    background: var(--white_A700);
    &.overflow-x-visible{
        overflow-x: visible !important;
        @media (max-width: 767px) {
            overflow-x: auto !important;
        }
    }

    .MuiTable-root {
        .MuiTableHead-root {
            background: var(--blue_gray_50);
        }
    }

    .MuiTableCell-root {
        padding: 13.5px 16px;
        border-color: var(--gray_200);
        min-height: 48px;
        font-family: 'Proxima Nova', sans-serif !important;
        &.MuiTableCell-head {
            font-size: 14px;
            font-weight: 600;
            color: var(--black_00);
            text-transform: capitalize;
        }
    }
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
}

.line-height-subjects {
    height: calc(100% - 80px);
}

.horizontal-tab {
    .MuiTabs-scroller {
        .MuiTabs-flexContainer {
            .MuiButtonBase-root {
                font-size: 14px;
                font-family: 'Proxima Nova', sans-serif;
                font-weight: 600;
                color: var(--black_00);
                text-transform: none;
                padding: 12px 16px;
                display: flex;
                flex-direction: row;
            }
        }

        .MuiTabs-indicator {
            background-color: var(--orange_300);
        }
    }
}

.bottom-divider {
    .MuiFormControlLabel-root {
        position: relative;
        padding: 10px 0;
        .MuiRadio-root {
            margin-top: -10px;
            margin-bottom: -10px;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 11px;
            right: -16px;
            height: 1px;
            background-color: var(--gray_200);
        }
    }
}

.detail-accordiun {
    width: 100%;
    .MuiPaper-root {
        box-shadow: none;

        &.Mui-expanded {
            margin-top: 0px;

            &::before {
                opacity: 1;
            }
        }
    }

    .MuiAccordionSummary-root {
        padding: 0px;
        min-height: 70px;
        width: 100%;

        &.Mui-expanded {
            min-height: 70px;

            .absolute {
                transform: rotate(180deg);
            }
        }
    }

    .MuiAccordionDetails-root {
        padding: 0px;
    }
}
.MuiAccordionSummary-root {
    &.Mui-expanded {
        .absolute {
            transform: rotate(180deg);
        }
    }
}

.upload-btn-wrapper {
    position: relative;

    .upload-btn {
        position: absolute;
        top: 0px;
        right: 0px;
    }
}


.burger-menu-link {
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.MuiMenuItem-root {
    border-bottom: 1px solid #E8E8E8 !important;    
    font-size: 14px !important;
    font-family: 'Proxima Nova', sans-serif !important;
    color: #000 !important;
    text-transform: capitalize;
    &:last-child {
        border-bottom: none !important;
    }
    &.Mui-selected {
        background-color: transparent !important;
    }
}
.MuiAutocomplete-listbox > li {
    border-bottom: 1px solid #E8E8E8 !important;    
    font-size: 14px !important;
    font-family: 'Proxima Nova', sans-serif !important;
    color: #000 !important;
    text-transform: capitalize;
    padding: 6px 16px !important;
    &:last-child {
        border-bottom: none !important;
    }
    &.Mui-selected {
        background-color: transparent !important;
    }
  }
.MuiButtonBase-root.MuiMenuItem-root{
    .MuiButtonBase-root.MuiCheckbox-root{
        padding: 0px;
        margin-right: 8px;
        margin-left: -7px;
    }
}

.MuiDateCalendar-root,
.MuiDateRangeCalendar-root {
    .Mui-selected {
        background-color: var(--primary-color) !important;
        color: var(--white_A700) !important;
    }

    .MuiDateRangePickerDay-rangeIntervalDayHighlight {
        background-color: #ba110b12 !important;
    }
}

.gray-accordion {
    .MuiAccordion-rounded {
        border-radius: 0px !important;
        margin-bottom: 16px !important;
        padding: 0px;
        background: transparent !important;
        box-shadow: none !important;

        &::before {
            display: none !important;
        }

        .MuiAccordionSummary-root {
            border-radius: 0px !important;
            background: var(--blue_gray_50);
            min-height: 40px;
            width: 100%;
            padding: 0px 16px;

            img[alt="remove"] {
                display: none;
            }

            img[alt="add"] {
                display: block;
            }

            &.Mui-expanded {
                min-height: 40px;

                img[alt="remove"] {
                    display: block;
                }

                img[alt="add"] {
                    display: none;
                }
            }

            .MuiAccordionSummary-content {
                margin: 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        &.Mui-expanded {
            .MuiAccordionSummary-root {
                .MuiAccordionSummary-content {
                    margin: 0px;
                }
            }
        }

        .MuiAccordionDetails-root {
            padding: 20px 0px 0px 0px;
        }
    }
}

.fc .fc-button-primary {
    background-color: var(--primary-color) !important;
    color: var(--white_A700) !important;
    border-color: var(--primary-color) !important;
}

/* datepicker-parent*/
.datepicker-parent {
    .MuiInputAdornment-positionEnd {
        position: absolute;
        width: 100%;
        top: 0;
        right: 0;
        transform: translate(0px, 0px);
        bottom: 0;
        left: 0;
        min-height: 56px;
        margin-left: 0;
        .MuiButtonBase-root{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: transparent;
            &:hover{
                background-color: transparent;
            }
            .MuiTouchRipple-root{
                display: none;
            }
        }
    }

    .MuiSvgIcon-root {
        background: url('../../public/images/icon-calendar.svg') no-repeat center center;
        background-size: contain;
        width: 24px;
        height: 24px;
        transform: none !important;

        path {
            display: none;
        }
    }
    button[disabled]{
        background: transparent !important;
        border: none !important;
    }
    .Mui-disabled{
        background: rgba(0, 0, 0, 0.12) !important;
        &.MuiFormLabel-root{
            background: transparent !important;
        }
    }
}

/* clock-icon*/
.MuiFormControl-root.clock-icon {
    .MuiSvgIcon-root.MuiSelect-iconFilled {
        background: url('../../public/images/clock-icon.svg') no-repeat center center !important;
        background-size: contain;
        width: 24px;
        height: 24px;
        transform: none !important;
        &.MuiSelect-iconOpen{
            transform: none !important;
        }
        path {
            display: none;
        }
    }
}

.riply-text-input {
    flex-direction: row !important;
    margin-top: 16px !important;
    align-items: center;
    .MuiFormControl-root {
        .MuiInputBase-root {
            background: var(--gray_50) !important;
            padding: 9px 50px 9px 16px !important;
            border: none;

            &:hover {
                background: var(--gray_50);
            }

            .MuiInputBase-input {
                background-color: transparent !important;
            }

            &::before {
                display: none;
            }
        }
    }
    &.h-56px{
        .MuiFormControl-root {
            .MuiInputBase-root {
                padding: 18px 50px 18px 16px !important;
            }
        }
    }
    &.m-0{
        margin: 0px !important;
    }
}

.vertical-tabs {
    .MuiTabs-root {
        border-top: 1px solid var(--gray_200);
        .MuiButtonBase-root {
            align-items: flex-start;
            justify-content: center;
            min-height: 40px;
            border-bottom: 1px solid var(--gray_200);
            font-size: 14px;
            font-family: 'Proxima Nova', sans-serif;
            color: var(--black_00);
            text-transform: none;
            transition: all 0.3s ease-in-out;
            padding: 0px 20px;
            @media (max-width: 1050px) {
                max-width: 100%;
            }
            @media (max-width: 550px) {
                max-width: 100%;
                width: 100%;
                padding: 12px 20px;
            }
            img.MuiTab-iconWrapper {
                margin-bottom: 0px !important;
                opacity: 0;
                transition: all 0.3s ease-in-out;
            }

            &.Mui-selected {
                background: var(--blue_gray_50);
                color: var(--black_00);
                font-weight: 600;

                img.MuiTab-iconWrapper {
                    opacity: 1;
                }
            }
        }

        .MuiTabs-indicator {
            display: none;
        }
    }
}

.lession-height {
    min-height: calc(100vh - 205px);
    height: calc(100vh - 205px);
    overflow: auto;
    @media (max-width: 1050px) {
        min-height: calc(100vh - 140px);
    height: calc(100vh - 140px);
    }
    .lesson-accordion {
        .MuiPaper-root {
            box-shadow: none;
            background: var(--gray_50);
            margin-bottom: 16px;

            &::before {
                display: none;
            }

            &.Mui-expanded {
                margin-top: 0px;

                &::before {
                    opacity: 1;
                }
            }
        }
    }
}
.accordions-show-none{
    .MuiAccordion-root{        
        box-shadow: none;
        background: transparent;
        border-bottom:  1px solid #E8E8E8;
        .MuiAccordionSummary-root{
            padding: 0px 0px !important;
        }
        .MuiAccordionDetails-root{
            padding: 0px 0px 20px 24px;
        }
        &::before {
            display: none;
        }

        &.Mui-expanded {
            margin: 0px !important;
            &::before {
                opacity: 1;
            }
        }        
    }
}
.manage-accordian {
    box-shadow: none !important;
    background: transparent;
    border: none;

    &.Mui-expanded {
        margin: 0px 0px !important;
    }

    &::before {
        display: none;
    }

    .MuiAccordionSummary-root {
        padding: 0px 0px !important;

        .MuiAccordionSummary-content {
            margin: 0px 0px !important;
        }

        .MuiAccordionSummary-expandIconWrapper {
            @media (max-width: 550px) {
                margin-top: -3px;
                margin-bottom: auto;
            }
        }
    }

    .MuiCollapse-root {
        .MuiAccordionDetails-root {
            padding: 20px 0px 0px 0px !important;
        }
    }
}

/* department-parent*/
.department-parent {
    .department-box {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @media (max-width: 1050px) {
            display: none;
            &.md-show{
                display: block;
                position: absolute;
                top: 69px;
            }
        }
        @media (max-width: 576px) {
            &.md-show{
                position: relative;
                top: unset;
            }
        }
        .department-row{
            &:hover .text-black_900{
                color: var(--primary-color);                
                img.h-5.w-5{
                    filter: grayscale(0);
                }
            }
            img.h-5.w-5{
                filter: grayscale(1);
            }
            .department-box-show-hover{
                display: none;
                height: 100%;
                margin-left: calc(100% - 17px);
                @media (max-width: 1050px) {
                    display: none;
                    width: 100%;
                    margin-left: 0px;
                }
            }
            &:hover{
                .department-box-show-hover{
                    display: block;
                    .text-black_900{
                        color: var(--black_00);
                        &:hover{
                            color: var(--primary-color);
                        }
                    }
                }
            }
        }
    }
    &.hide{
        .department-box{
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            transform: translateY(-20px);
            @media (max-width: 1050px) {
                visibility: visible;
                opacity: 1;
                transition: all 0.3s ease-in-out;
                transform: translateY(0px);
            }
        }
    }
    &.show{
        .department-box{
            visibility: visible;
            opacity: 1;
            transition: all 0.3s ease-in-out;
            transform: translateY(0px);
        }
    }
}

/* purchase-slider*/
.purchase-slider {
    width: calc(100% + 50px);
    margin: 0px -22px;
    @media (max-width: 550px) {
        width: 100%;
        margin: 0;
    }
    .slick-track {
        display: flex;
        gap: 32px;
        @media (max-width: 550px) {
            gap: 0;
        }
    }

    .slick-prev,
    .slick-next {
        &:before {
            content: '' !important;
            color: var(--black_00);
            font-family: "Proxima Nova", sans-serif !important;
            background: url('../../public/images/arrow_forward.svg') no-repeat center center;
            background-size: contain;
            display: block;
            width: 24px;
            height: 24px;
            
        }
    }
    .slick-prev{
        &:before {
            transform: rotate(180deg);
        }
    }
}

.hamburger {
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    .hamburger-box {
      width: 25px;
      height: 22px;
      display: inline-block;
      position: relative;
      .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: -3.5px;
        width: 25px;
        height: 4px;
        background-color: var(--primary-color);
        border-radius: 50px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
        &::before {
          content: "";
          display: block;
          width: 25px;
          height: 4px;
          background-color: var(--primary-color);
          border-radius: 50px;
          position: absolute;
          transition-property: transform;
          transition-duration: 0.15s;
          transition-timing-function: ease;
          top: -11px;
        }
        &::after {
          content: "";
          display: block;
          width: 25px;
          height: 4px;
          background-color: var(--primary-color);
          border-radius: 50px;
          position: absolute;
          transition-property: transform;
          transition-duration: 0.15s;
          transition-timing-function: ease;
          bottom: -11px;
        }
      }
    }
    &.hamburger--slider {
        .hamburger-box {
            .hamburger-inner {
                top: 3.5px;
            &::before {
                top: 9px;
                transition-property: transform, opacity;
                transition-timing-function: ease;
                transition-duration: 0.15s;
            }
            &::after {
                bottom: -18px;
            }
            }
        }
        &.is-active {
            .hamburger-box {
                width: 25px;
                height: 25px;
                .hamburger-inner {
                    transform: translate3d(0, 15px, 0) rotate(45deg);
                    top: 0;
                    &::before {
                    transform: rotate(-45deg) translate3d( -27px, -9px, 0 );
                    opacity: 0;
                    }
                    &::after {
                    transform: translate3d(0, -18px, 0) rotate(-90deg);
                    }
                }
            }
        }
      }
  }

.create-lesson-md {
    display: none !important;
    @media (max-width: 550px) {
        display: flex !important;
    }
}
/* Calender*/
.fc {
    .fc-toolbar {
        &.fc-header-toolbar {
            @media (max-width: 550px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5rem;
            }
        }
    }
    table {
        @media (max-width: 550px) {
            font-size: 0.75rem;
        }
    }
}

/* chat-custom-height*/
.chat-custome-height{
    min-height: calc(100vh - 50vh);
    max-height: calc(100vh - 50vh);
}

.student-header-right{
    img.h-6{
        filter: brightness(0);
    }
    .cursor-pointer{
        &:hover,&.active{
            img.h-6{
                filter: brightness(1);
            }
            .text-black_900{
                color: var(--red_900);
            }
            .department-box {
                .text-black_900{
                    color: var(--black_900);
                }
                .h-5{
                    filter: brightness(0);
                }
            }
        }        
    }
    .department-row{
        &:hover{
            .text-black_900{
                color: var(--red_900) !important;
            }
            .h-5{
                filter: brightness(1) !important;
            }
            .department-box-show-hover{
                .text-black_900{
                    color: var(--black_900) !important;
                    &:hover{
                        color: var(--red_900) !important;
                    }
                }
            }
        }
    }
}

*::-webkit-scrollbar-track
{
	background-color: transparent;
}

*::-webkit-scrollbar
{
	width: 6px;
    height: 6px;
	background-color: transparent;
}

*::-webkit-scrollbar-thumb
{
	background-color:  var(--primary-color);
    border-radius: 10px;
}

.MuiSnackbar-root{
    top: 30px !important;
    right: 30px !important;
    bottom: auto !important;
    left: auto !important;
}
.trunk-one-line{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}
.Mui-disabled{
    &:hover{
        background-color: rgba(0, 0, 0, 0.12) !important;
    }
    .MuiInputBase-input{
        background: transparent !important;
    }
}

.terms-condition {
    label {
        margin-right: 0px !important;
    }
}
.myusessionblock{
    .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        right: 0;
        top: 4px;
    }
}
.radioTopAlign{
    label.MuiFormControlLabel-root.flex {
        align-items: flex-start !important;
        span.MuiButtonBase-root {
            padding-top: 0 !important;
            padding-bottom: 0;
        }
    }
}
.ds-upcoming-slider{
    @media (max-width: 550px) {
        width: 100%;
        margin: 0;
    }
    .slick-track {
        display: flex;
        gap: 20px;
        &::before, &::after {
            display: none;
        }
        @media (max-width: 550px) {
            gap: 0;
        }
    }
    .slick-prev,
    .slick-next {
        outline: none;
        background: transparent;
        width: 65px;
        height: 100%;
        border-radius: 0;
        z-index: 9 !important;
        background: transparent;
        &:before {
            color: var(--black_00);
            font-family: "Proxima Nova", sans-serif !important;
        }
        &::after {
            content: "";
            width: 24px;
            height: 24px;
            border: 2px solid #000;
            position: absolute;
            border-radius: 50%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        @media screen and (max-width: 990px) {
            width: 35px;
        }
        @media screen and (max-width: 550px) {
            width: 24px;
        }
    }
    .slick-prev{
        left: -20px;
        background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);
        @media screen and (max-width: 550px) {
            left: -12px;
            background: transparent;
        }
    }
    .slick-next {
        right: -20px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);
        @media screen and (max-width: 550px) {
            right: -12px;
            background: transparent;
        }
    }
    button.slick-arrow.slick-disabled {
        opacity: 0;
    }
}
.ds-popular-slider{
    @media (max-width: 550px) {
        width: 100%;
        margin: 0;
    }
    .slick-track {
        display: flex;
        gap: 30px;
        &::before, &::after {
            display: none;
        }
        @media (max-width: 550px) {
            gap: 0;
        }
    }
    .slick-prev,
    .slick-next {
        outline: none;
        background: transparent;
        width: 65px;
        height: 100%;
        border-radius: 0;
        z-index: 9 !important;
        background: transparent;
        &:before {
            color: var(--black_00);
            font-family: "Proxima Nova", sans-serif !important;
        }
        &::after {
            content: "";
            width: 24px;
            height: 24px;
            border: 2px solid #000;
            position: absolute;
            border-radius: 50%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        @media screen and (max-width: 990px) {
            width: 35px;
        }
        @media screen and (max-width: 550px) {
            width: 24px;
        }
    }
    .slick-prev{
        left: -0;
        background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);
        @media screen and (max-width: 550px) {
            left: 0;
            background: transparent;
        }
    }
    .slick-next {
        right: 0;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);
        @media screen and (max-width: 550px) {
            right: 0;
            background: transparent;
        }
    }
    button.slick-arrow.slick-disabled {
        opacity: 0;
    }
}
.linearPRogressbar{
    .css-eglki6-MuiLinearProgress-root{
        height: 6px;
        background-color:#FAFAFA;
    }
    .css-5xe99f-MuiLinearProgress-bar1{
        background-color: #BA110B;
    }
}
.chatSearchBox{
    .mb-0{
        margin-bottom: 0px !important;
    }
    .MuiFormControl-root .MuiInputBase-root {
        padding: 12px 12px 12px !important;
    }
    .MuiFormControl-root .MuiInputBase-root::before {
        border: 1px solid #E8E8E8;
        border-left: 0;
        border-right: 0;
    }
    .MuiFormControl-root .MuiInputBase-root::after {
        border: 1px solid #E8E8E8;
        border-left: 0;
        border-right: 0;
    }
}
.homebannerSec{
    position: relative;
    z-index: 10;
    .homebannerslide{
        height: 668px;
        @media (max-width: 550px) {
            height: 450px;
        }
    }
    .slick-list {
        width: 100% !important;
        padding: 0 !important;

    }
    ul.slick-dots {
        top: 0;
        bottom: 0;
        margin: auto;
        height: max-content;
        z-index: 11;
        width: 30px;
        right: 0;
        position: absolute;
        display: flex !important;
        flex-direction: column;
        li{
            width: 8px;
            padding: 0;
            background: #fff;
            height: 8px;
            border-radius: 50px;
            margin: 5px 0;
            transition: all 200ms;
            button{
                width: 8px;
                padding: 0;
                font-size: 14px;
                height: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: all 200ms;
                &:before{
                    display: none;
                }
            }
            &.slick-active{
                background: #BA110B ;
                height: 30px;
            }
        }
    }
    &::before{
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .40);
        z-index: 1;
    }
    .bannerContent{
        z-index: 2;
    }
}
.hbgafterSrcsec2{
    position: absolute;
    right: 0;
    top: 0px;
    width: 267px;
    height: auto;
    object-fit: cover;
    object-position: bottom;
    @media (max-width: 991px) {
        width: 200px;
    }
}
.hbgbeforeSrcsec2 {
    position: absolute;
    left: -30px;
    bottom: -227px;
    max-width: 477.781px;
}
.findalessonSlider{
    @media screen and (max-width: 990px) {
        padding-top: 50px;
    }
    .slick-list {
        overflow: visible;
        @media (max-width: 550px) {
            overflow: hidden;
        }
    }
    .slick-track {
        &:before, &:after {
            display: none;
        }
        display: flex;
        gap: 30px;
        @media (max-width: 550px) {
            gap: 0;
        }
        
    }
    .slick-prev,
    .slick-next {
        border: 1px solid #000;
        outline: none;
        background: transparent;
        width: 30px;
        height: 30px;
        border-radius: 0px;
        z-index: 10 !important;
        background: #fff;
        top: -90px;
        @media screen and (max-width: 990px) {
            top: -50px;
        }
        font-size: 0;
        position: absolute;
        &:hover{
            border-radius: 0px;
            background: #fff;
        }
        &:before {
            color: rgba(186, 17, 11, 1);
            font-family: "Proxima Nova", sans-serif !important;
            font-size: 16px;
        }
    }
    button.slick-arrow.slick-prev{
        right: 45px !important;
        z-index: 10 !important;
        cursor: pointer;
    }
    button.slick-arrow.slick-prev.slick-disabled {
        display: none;
    }
    button.slick-arrow.slick-next.slick-disabled{
        display: none;
    }
    .slick-next {
        right: 0px;
    }
    .slick-next:before {
        content: '→';
    }
    .slick-prev:before {
        content: '←';
    }
}
.custom-light-box{

    .slick-prev,
    .slick-next {
        border: none;
        outline: none;
        background: transparent;
        width: 30px;
        height: 38px;
        border-radius: 0px;
        z-index: 10 !important;
        top: calc(285px - 50%);       
        font-size: 0;
        position: absolute;
        &:hover{
            border-radius: 0px;
            background: #fff;
        }
        &:before {
            color: rgba(186, 17, 11, 1);
            font-family: "Proxima Nova", sans-serif !important;
            font-size: 28px;
        }
    }
    button.slick-arrow.slick-prev{
        right: auto !important;
        left: 10px;
        z-index: 10 !important;
        cursor: pointer;
    }
    button.slick-arrow.slick-prev.slick-disabled {
        display: none;
    }
    button.slick-arrow.slick-next.slick-disabled{
        display: none;
    }
    .slick-next {
        right: 10px;
    }
    .slick-next:before {
        content: '→';
    }
    .slick-prev:before {
        content: '←';
    }
}
.upload-btn-wrapper{
    .MuiFormControl-root.MuiFormControl-fullWidth{
        pointer-events: none;
    }
    label.MuiFormLabel-root {
        width: 60%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.react-switch-bg[style="height: 18px; width: 32px; margin: 0px; position: relative; background: rgb(0, 162, 151); border-radius: 9px; cursor: pointer; transition: background 0.25s ease 0s;"]{
    background: #BA110B !important;
}
.react-switch-bg[style="height: 18px; width: 32px; margin: 0px; position: relative; background: rgb(196, 196, 196); border-radius: 9px; cursor: pointer; transition: background 0.25s ease 0s;"] {
    background: #C4C4C4;
}
.stSignuostep2{
    span.text-sm.text-red_900.absolute.left-0{
        position: relative;
        bottom: 0;
    }
}
.MuiFormControl-root .MuiInputBase-root::after {
    top: 0px;
    border: 0 !important;
}
.hmTestimonialSlider{
    .slick-list {
        width: 100% !important;
        padding: 40px 0 !important;

    }
    ul.slick-dots {
        display: flex !important;
        justify-content: center;
        margin-top: 0px;
        position: relative;
        z-index: 1;
        li{
            width: 8px;
            padding: 0;
            background: #E8E8E8;
            height: 8px;
            border-radius: 50px;
            margin: 0 5px;
            button{
                width: 8px;
                padding: 0;
                font-size: 14px;
                height: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: all 200ms;
                &:before{
                    display: none;
                }
            }
            &.slick-active{
                background: #BA110B ;
                width: 30px;
            }
        }
    }
    .slick-slide{
        padding: 0 15px;
        @media screen and (max-width: 550px) {
            padding: 0;
        }
    }
}
.capitalize{
    text-transform: capitalize;
}
.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation,.MuiAutocomplete-popper{
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16) !important;
    border: 1px solid var(--gray_600) !important;
    border-radius: 0px !important;
}
.MuiList-root.MuiList-padding.MuiMenu-list{
    max-height: 315px;
    padding: 0px 0px;
}
.truncated-4-line{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  word-break: break-word;
}
.MuiTabs-scrollButtons{
    @media (max-width: 599px) {
        display: flex !important;
    }
}
.radioTopAlign{
    .MuiTouchRipple-root{
        display: none;
    }
    .MuiIconButton-colorSecondary:hover{
        background-color: transparent !important;
    }
}
.info{
    transition: all 200ms;
    .info-box{
        visibility: hidden;
        transform:  translateX(10px);
        transition: all 200ms;
        z-index: 5;
        &.arrow-right,&.arrow-left{
            &::before{
                content: '';
                position: absolute;
                right: -5px;
                top: 0px;
                bottom: 0px;
                margin: auto;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                background: #ffffff;
                border: 1px solid #e8e8e8;
                border-bottom: none;
                border-left: none;
            }
        }
        &.arrow-left{
            &::before{
                right: auto;
                left: -5px;
                transform: rotate(225deg);
            }
        }
        .flex.items-center.justify-between.border-b:last-child{
            border-bottom: none !important;
        }
    }
    &:hover{
        .info-box{
            visibility: visible;
            transform: translate(0px);
        }
    }
}

.small-datepickre{
    .datepicker-parent{
        transform: scale(.85);
        transform-origin: right;
        margin-bottom: 6px !important;
    }
}

.social-google {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
    @media (max-width: 576px) {
        max-width: 100%;
    }
    iframe {
        @media screen and (max-width: 424px) {
            transform: scale(0.8) translatex(-40px);            
        }
    }
}
.normal-case.capitalize{
    text-transform: none !important;
}
.wanttopostbox .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
    border: transparent;
}
.fc-header-toolbar{
    .fc-toolbar-chunk{
        .fc-button-group{
            button{
                margin-left: 0px !important;
                border-radius: 0px !important;
                &[title="week view"]{
                    border-left: 1px solid #ffffff;
                    border-right: 1px solid #ffffff;
                }
            }
            button+button[aria-pressed="false"]{
                display: none;
            }
        }
    }
    .fc-button.fc-button-primary{
        box-shadow: none !important;
        line-height: 27px ;
        background: #ffffff !important;
        color: #BA110B !important;
        &.fc-button-active{
            background: #BA110B !important;
            color: #ffffff !important;
        }
    }
}
.fc-toolbar > * > :not(:first-child){
    margin-left: 0px !important;
    border-radius: 0px !important;
}
.conference-large-box{   
    display: grid;
    min-height: 50vh;
    width: 100%;  
}
.conference-parent-box{
    min-height: calc(100vh - 50vh - 70px);
    width: 100%;    
    gap: 10px;
    display: grid;    
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 10px;
    padding: 10px 0px;
    .video-mute-end{
        pointer-events: none;
        .people-list{
            display: none;
        }
    }
}
.conference-box{
    min-height: auto;
    width: 100%;
    background: #808080;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .dummy-icon{
        border-radius: 50%;
        width: 110px;
        height: 110px;
        object-fit: scale-down;
    }
    .name{
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 14px;
        color: #ffffff;
        background: #00000070;
        border-radius: 20px;
        padding: 8px 20px;
    }
    .video-mute-end{
        position: absolute;
        bottom: 20px;
        width: auto;
        min-width: 140px;
        border-radius: 40px;
        background: #ffffff;
        padding: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        @media (max-width: 767px) {
            bottom: 0px;
        }
        .MuiSvgIcon-root{
            cursor: pointer;
            margin: 0px 10px;
            &:hover{
                color: #BA110B;
            }
        }
        .MuiSvgIcon-root.mx-0{
            margin: 0px 0px !important;
        }
        .people-list{
            .MuiBadge-badge{
                position: absolute;
                right: 12px;
                top: 4px;
                background-color: #BA110B;
                color: #fff;
                padding: 0;
                min-width: 14px;
                font-size: 10px;
                height: 14px;
            }
        }
    }
}
.participant-box{
    min-width: 250px;
    padding: 8px 0px 8px 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    margin: 0px 0px;
    .img-side{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .name-box{
        padding: 0px 10px;
        .p3{
            font-size: 14px;
            font-weight: 600;
            margin: 0px;
            width: 100%;
            max-width: 200px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .p4{
            font-size: 12px;
            margin: 0px;
        }
    }
    .MuiSvgIcon-root{
        margin: 0px 0px !important;
    }
}
.fc-h-event,
.fc-event{
    background: #5394DA !important;
    color: #ffffff !important;
}
.line-clamp-180{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    word-wrap: break-word;
}
.font-normal,.text-sm{
    word-break: break-word;
}
.drop-search{
    width: 100%;
    transform: scale(0.9) !important;
    margin-top: 6px !important;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        right: 8px;
        top: 5px;
        background: url('../../public/images/img_search.svg') no-repeat;
        width: 24px;
        height: 24px;
    }
    .MuiInputBase-root{
        padding: 0px !important;
    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall{
        padding: 8px 8px 8px 8px !important;
    }
}
}
.color-box{
    min-width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
}
.custom-light-box{
    display: block;
    width: 100%;
    height: 295px;
    position: relative;
    .custom-img-light-box{
        width: 100%;
        height: 295px;
        border: 1px solid transparent;
        img.object-contain,video.object-contain{            
            background-color: #f9f9f9;
        }
    }
}
.border-transparent.border-black_900{
    border: 1px solid var(--black_00) !important;
}
.font-600{
    font-weight: 600;
}
.comments-section-height{
    max-height: 400px;
    overflow: auto;
    margin-bottom: 0px;
}
.active-link{
    color: var(--red_900) !important;    
}
.label-size-13{
    .MuiFormControl-root{
        .MuiFormLabel-root{
            font-size: 13px;
            &[data-shrink="true"]{
                font-size: 12px;
            }
        }
    }
}
.line-height-normal{
    line-height: normal !important;
}
.bg-orange_300{
    background-color: var(--orange_300) !important;
}   

.static-page{
    *{
        font-family: 'Proxima Nova', sans-serif !important;
    }
    h1{
        font-size: 42px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    h2{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    h3{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    h4{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    h5{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
    }   
    p{
        font-size: 14px;
        margin-bottom: 20px;
    }
    ul{
        margin-bottom: 20px;
        padding-left: 20px;
        li{
            font-size: 14px;
            margin-bottom: 10px;
            list-style: disc;
            a{
                margin: 0px 4px;
            }
        }
    }
    ol{
        margin-bottom: 20px;
        li{
            font-size: 14px;
            margin-bottom: 10px;
            a{
                margin: 0px 4px;
            }
            
        }
    }
    a{
        color: var(--red_900);
        text-decoration: underline;
        display: inline-block;
    }    
}
.faq-collapsed{
    background-color: transparent !important;
    box-shadow: none !important;
    margin: 16px 0px !important;
    &::before{
        display: none !important;
    }
    &.Mui-expanded {
        .Mui-expanded{
            .text-black_900{
                color: var(--red_900) !important;
            }
        }
    }
    .MuiButtonBase-root.MuiAccordionSummary-root{
        padding: 0px 0px !important;
    }
    .MuiCollapse-wrapperInner{
        .MuiAccordion-region{
            .MuiAccordionDetails-root{
                padding: 0px 0px !important;
            }
        }
    }
}
.bg-deep_purple_A200{
    background-color: var(--purple_400) !important;
}
.font-regular.font-semibold{
    font-weight: 400 !important;
}
.search-input{
    .MuiInputBase-root.MuiFilledInput-root{
        padding-right: 50px !important;
    }
}
.shorting {
    white-space: nowrap;
}
.shorting-inner{
    margin: 0px 4px;
    img{
        width: 9px;
        &:hover{
            cursor: pointer;
            filter: brightness(.75);
        }
    }    
}
.confetti-bg{
    background: url('../../public/images/confetti.png') no-repeat;
    height: 100%;
    width: 100%;
}
.remove-inner-color{
    .find-color{
        display: none !important;
    }
}
.bg-black-opacity{
    background: rgba(0, 0, 0, 0.05);
}
.rating-large-box{
    margin-bottom: 181px;
    &.hide-after-rating{
        display: none;
    }
    .MuiRating-root.MuiRating-sizeLarge{
        font-size: 48px !important;
    }
}
.fc-button{
    text-transform: capitalize !important;
}
.fc .fc-daygrid-event-harness {
    position: relative;
    margin-top: 2px !important;
}
.width-full-350{
    .MuiTypography-root.MuiTypography-body1{
        max-width: 350px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
    transition: unset !important;
}
.MuiAlert-message{
    text-transform: none !important;
}
* {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
*:focus {
    outline: none !important;
}
.tool-parent{
    position: relative;
    .tooltip{
        visibility: hidden;
        transform: translateY(-10px);
        transition: all 100ms;
        position: absolute;
        left: -85px;
        right: auto;
        top: 20px;
        width: 250px;
        margin: auto;
        background: #ffffff;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 21%);
        z-index: 5;
        @media (max-width: 1000px) {
            position: fixed;
            top: auto;
            left: 15px;
            right: auto;
            width: calc(100vw - 30px);
        }
    }
    &:hover{
        .tooltip{
            visibility: visible;
            transform: translateY(0px);
            transition: all 100ms;
            @media (max-width: 1000px) {
                transform: translateY(20px);
            }
        }
    }
}

.noDisable {
    .Mui-disabled {

        opacity: 1 !important;
        pointer-events: auto !important;
    }
}

.progress-bar {
    margin-top: 20px;
    height: 10px;
    background-color: #ddd;
    position: relative;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s ease;
  }
  .text-white_A700.text-black_900{
    color: #ffffff !important;
  }
  .notification-type{
    position: absolute;
    top: -5px;
    right: -5px;
    background: #5394DA;
    color: #fff;
    font-size: 12px;
    border-radius: 50%;
    padding: 0px 4px;
  }
  .activeLInk {
    color: var(--red_900) !important;
  }
.max-w-full-150{
    width: 100%;
    max-width: calc(100% - 150px);
    @media (max-width:576px) {
        max-width: 100%;
    }
}
.riply-text-input{
    .text-sm.text-red_900.relative.left-0{
        position: absolute !important;
        left: 16px !important;
        margin-bottom: 0px !important;
        bottom: 3px !important;
    }
}
.send-icon{
    filter: contrast(0) brightness(0);
    &.typing{
        filter: contrast(1) brightness(1);
    }
}

.stripe-card {
    margin-top: 20px !important;
    .StripeElement {
        padding: 10px 12px !important;
        border: 1px solid #E8E8E8 !important;
        background-color: #eee;
    }
    button {
        margin-top: 20px !important;
    }
}
.react-tel-input{
    width: 100%;
    .flag-dropdown {
        background: transparent !important;
        border: none !important;
        .selected-flag{
            height: calc(100% - 4px) !important;
            background: transparent !important;
            position: relative;
            top: 1px;
            left: 1px;
            border: none;
            &:hover, &:focus{
                background: transparent !important;
            }
            .flag {
                .arrow{
                    display: none !important;
                }
            }
        }
    }
    .special-label{
        display: none !important;
    }
    .form-control {
        height: 56px !important;
        padding-left: 32px !important;
        width: 100% !important;
        border: 1px solid var(--gray_600) !important;
        border-radius: 0px !important;
    }
}
.hover\:shadow-hlg:hover {
    transition: all 20ms !important;
    --tw-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15) !important;
    --tw-shadow-colored: 0px 10px 20px 0px var(--tw-shadow-color) !important;
}
